const WorkExperience = [
    {
        id: 1,
        title: "Senior Software Developer",
        company: "Фирма 1С",
        yearsActive: "Ноябрь 2022 - Настоящее время",
        information: [
            "Разработка программного обеспечения"
        ],
    },
    {
        id: 2,
        title: ".NET System Architect",
        company: "Альфа-Банк",
        yearsActive: "Февраль 2022 - Ноябрь 2022",
        information: [
            "Управлял командами разработки, включая проектирование архитектуры систем, приложений.",
            "Разрабатывал архитектуры приложений, микросервисов с использованием DDD",
            "Сопровождал DevOps с использованием Docker, Mesos + Marathon",
        ],
    },
    {
        id: 3,
        title: ".NET Teach Lead",
        company: "Альфа-Банк",
        yearsActive: "Ноябрь 2019 - Февраль 2022",
        information: [
            "Проектировал архитектуру и разрабатывал новый продукт бэк-офиса по аналитике и формированию кредитных предложений для юридических лиц на .NET 5, MSSQL, Kafka, MongoDB, SignalR и т.д.",
            "Реализовывал интеграцию монолитного легаси-продукта с БКИ. Для этого создавал микросервисы для интеграции с другими внешними и внутренними сервисами.",
            "Выполнял работу по миграции монолита в микросервисы на .NET Core 3.1. Выполнял перенос бизнес-логики из хранимых процедур базы данных монолита в микросервисы.",
            "Выполнял оптимизацию SQL-процедур.",
            "Разрабатывал DevOps механизмы для пайплайнов с использованием Groovy. Реализовал множество утилит, одна из которых - бесшовная генерация документации на API микросервисов в формате MD, WSD для хранения в git (на основе анализа кода api).",
            "Проводил технические собеседования с кандидатами на позицию разработчиков."
        ],
    },
    {
        id: 4,
        title: ".Net Developer (TeamLead)",
        company: "Калуга Астрал",
        yearsActive: "Novembe 2015 - January 2016",
        information: [
            "Served as a dedicated and supportive Intern in a fast-paced environment",
            "Worked to solve complex problems using the latest in Mobile and Web Technologies.",
        ],
    }
];

export default WorkExperience;
