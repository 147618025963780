const Education = [
    {
        id: 1,
        title: "Специалист по защите информации",
        institution: "МГТУ им. Н.Э. Баумана",
        yearsActive: "Сентябрь 2013 - Июнь 2019",
        information: [
            "Диплом специалиста по защите информации",
            "Средний балл: 4.5"
        ],
    }
];

export default Education;
