import React from 'react';
import { FaVk, FaTelegram, FaLinkedinIn, FaInstagram } from 'react-icons/fa';

const HeaderSocials = () => {
    return (
        <div className='home__socials'>
            <a href='https://t.me/gegobite' className='home__social-link' target='_blank' rel='noreferrer'>
                <FaTelegram />
            </a>

            <a href='https://instagram.com/djredthrevd' className='home__social-link' target='_blank' rel='noreferrer'>
                <FaInstagram />
            </a>

            <a href='https://www.linkedin.com/in/gegobite' className='home__social-link' target='_blank' rel='noreferrer'>
                <FaLinkedinIn />
            </a>

            <a href='https://vk.com/redthrevd' className='home__social-link' target='_blank' rel='noreferrer'>
                <FaVk />
            </a>
        </div>
    );
};

export default HeaderSocials;
