import React from 'react';
import './About.css';
import Image from '../../assets/profile.png';
import Resume from '../../assets/resume.pdf';
import AboutBox from './AboutBox';

const About = () => {
    const downloadResume = async () => {
        window.open(Resume, '_blank');
    }


    return (
        <section className="about container section" id="about">
            <h2 className="section__title">Обо мне </h2>

            <div className="about__container grid">
                <img src={Image} alt="" className='about__img' />

                <div className="about__data grid">
                    <div className="about__info">
                        <p className="about__description">
                            Привет! Я Игорь и на текущий момент я являюсь профессиональным разработчиком программного обеспечения.<br /><br />
                            У меня больше 6 лет опыта в программировании. Я создавал различные приложения, такие как веб-приложения, настольные приложения и другие.<br /><br />
                            В своей работе владею и применяю следующие языки:
                        </p>
                        <ul className="about__list">
                            <li>C#</li>
                            <li>JavaScript (ES6+)</li>
                            <li>TypeScript</li>
                            <li>React</li>
                            <li>Node.js</li>
                            <li>TSQL</li>
                        </ul>
                        <p className="about__description">
                            Так же в своих проектах применяю следующие технологии:
                        </p>
                        <ul className="about__list">
                            <li>.NET 6.0</li>
                            <li>ASP.NET Web API</li>
                            <li>WPF, WinForms</li>
                            <li>Queue (Apache Kafka, RabbitMQ)</li>
                            <li>EFCore</li>
                            <li>IoC-containers / DI-containers (Microsoft .NET, Ninject, Autofac)</li>
                            <li>Unit Testing (xUnit, nUnit, Fluent Assertions, Moq)</li>
                            <li>DevOps (Docker, Mesos, Marathon)</li>
                            <li>Logs (Serilog, Log4Net, NLog)</li>
                            <li>HangFire</li>
                            <li>SignalR</li>
                        </ul>
                        <p className="about__description">
                            Для разработки баз данных использую:
                        </p>
                        <ul className="about__list">
                            <li>Microsoft SQL Server</li>
                            <li>Mongo DB</li>
                            <li>MySQL</li>
                            <li>Oracle</li>
                            <li>PostgreSQL</li>
                        </ul>
                        <button className="btn" onClick={downloadResume}>Скачать резюме</button>
                    </div>

                    {/* <div className="about__skills grid">
                        <div className="skills__data">
                            <div className="skills__titles">
                                <h3 className="skills__name">Development</h3>
                                <span className="skills__number">90%</span>
                            </div>

                            <div className="skills__bar">
                                <span className="skills__percentage development">

                                </span>
                            </div>
                        </div>

                        <div className="skills__data">
                            <div className="skills__titles">
                                <h3 className="skills__name">UI/UX Design</h3>
                                <span className="skills__number">80%</span>
                            </div>

                            <div className="skills__bar">
                                <span className="skills__percentage ui__design">

                                </span>
                            </div>
                        </div>

                        <div className="skills__data">
                            <div className="skills__titles">
                                <h3 className="skills__name">Photography</h3>
                                <span className="skills__number">60%</span>
                            </div>

                            <div className="skills__bar">
                                <span className="skills__percentage photography">

                                </span>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>

            <AboutBox />
        </section>
    )
}

export default About